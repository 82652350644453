export class CustomField {
  fieldName: string = '';

  constructor(json?: any) {
    if (json) {
      this.fieldName = json.fieldName || '';
    }
  }
}

export enum VerifiedNumberStatus {
  Unverified  = 'Unverified',
  Verifying   = 'Verifying',
  Verified    = 'Verified',
}

export class OutgoingPhoneNumber {
  number: string;
  status: VerifiedNumberStatus;
  provider: string;

  constructor(json?: any) {
    if (json) {
      this.number = json.number || '';
      this.status = json.status || VerifiedNumberStatus.Unverified;
      this.provider = json.provider || '';
    } else {
      this.number = '';
      this.status = VerifiedNumberStatus.Unverified;
      this.provider = '';
    }
  }
}

export class Settings {
  customContactFields: CustomField[] = [];
  areaCodes: string[] = [];
  brand: string = '';
  outgoingPhoneNumbers: OutgoingPhoneNumber[] = [];
  outgoingProvider: string|undefined;
  outgoingApplicationSid: string|undefined;
  advancedBulkAssign: boolean = false;
  emailsEnabled: boolean = false;
  imagesEnabled: boolean = false;
  importUsers: boolean = false;
  lastNameFirst: boolean = false;
  bundleSid: string|undefined;
  brandSid: string|undefined;
  hubSid: string|undefined;
  campaignSid: string|undefined;
  messagingSid: string|undefined;

  constructor(json?: any) {
    if (json) {
      this.customContactFields = json.customContactFields || [];
      this.areaCodes = json.areaCodes || [];
      this.brand = json.brand || '';
      this.outgoingPhoneNumbers = json.outgoingPhoneNumbers || [];
      this.advancedBulkAssign = json.advancedBulkAssign || false;
      this.emailsEnabled = json.emailsEnabled || false;
      this.imagesEnabled = json.imagesEnabled || false;
      this.lastNameFirst = json.lastNameFirst || false;
      this.importUsers = json.importUsers || false;
      this.outgoingProvider = json.outgoingProvider;
      this.outgoingApplicationSid = json.outgoingApplicationSid;
      this.bundleSid = json.bundleSid;
      this.brandSid = json.brandSid;
      this.hubSid = json.hubSid;
      this.campaignSid = json.campaignSid;
      this.messagingSid = json.messagingSid;
    }
  }
}
