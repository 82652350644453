<section class="slice slice-navbar bg-gradient-primary" data-separator-bg="secondary"><div></div></section>

<section class="slice slice-lg">
  <div class="container">

<div class=WordSection1>

<p class=MsoNormal align=center style='margin-bottom:12.0pt;text-align:center;
line-height:normal'><b><span style='font-family:"Calibri",sans-serif'>TERMS OF
SERVICE</span></b></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;text-justify:
inter-ideograph;line-height:normal'><b><span style='font-family:"Calibri",sans-serif'>PLEASE
READ THESE TERMS OF SERVICE CAREFULLY. BY SIGNING “ACCEPTED AND AGREED TO,”
CUSTOMER AGREES TO THESE TERMS AND CONDITIONS.</span></b></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;text-justify:
inter-ideograph;line-height:normal'><span style='font-family:"Calibri",sans-serif'>These
Terms of Service constitute an agreement (this “<u>Agreement</u>”) by and
between Parlance LLC, a Georgia Limited Liability Company whose principal place
of business<span style='letter-spacing:.1pt'> </span>is 437 Memorial Dr, Ste
A2, Atlanta, GA 30312 (“<u>Vendor</u>”) and the corporation, LLC, partnership,
sole proprietorship, or other business entity executing this Agreement (“<u>Customer</u>”).
This Agreement is effective as of the date Customer signs  “Accepted and Agreed
To” (the “<u>Effective Date</u>”). Customer’s use of and Vendor’s provision of
Vendor’s System (as defined below in Section </span><span
style='font-family:"Calibri",sans-serif'>1.2</span><span style='font-family:
"Calibri",sans-serif'>) are governed by this Agreement.</span></p>

<p class=MsoBodyText style='margin-bottom:12.0pt;text-align:justify;text-justify:
inter-ideograph'><span style='font-size:11.0pt;font-family:"Calibri",sans-serif'>EACH
PARTY ACKNOWLEDGES THAT IT HAS READ THIS AGREEMENT, UNDERSTANDS IT, AND AGREES
TO BE BOUND BY ITS TERMS, AND THAT THE PERSON SIGNING ON ITS BEHALF HAS BEEN
AUTHORIZED TO DO SO. THE PERSON EXECUTING THIS AGREEMENT ON CUSTOMER’S BEHALF
REPRESENTS THAT HE OR SHE HAS THE AUTHORITY TO BIND CUSTOMER TO THESE TERMS AND
CONDITIONS.</span></p>

<p class=MsoNormal style='margin-bottom:12.0pt;margin-left:0in;text-align:justify;
text-justify:inter-ideograph;text-indent:0in;line-height:normal'><b><span
style='font-family:"Calibri",sans-serif'>1.</span></b><b><u><span
style='font-family:"Calibri",sans-serif'>  DEFINITIONS</span></u></b><b><span
style='font-family:"Calibri",sans-serif'>. </span></b><span style='font-family:
"Calibri",sans-serif'>The following capitalized terms <span style='letter-spacing:
.05pt'>will</span> ha<span style='letter-spacing:.05pt'>v</span>e the following
meanings whene<span style='letter-spacing:.05pt'>v</span>er used in this<span
style='letter-spacing:.1pt'> </span>Agreement.</span></p>

<p class=MsoNormal style='margin-bottom:12.0pt;margin-left:.35in;text-align:
justify;text-justify:inter-ideograph;text-indent:-.35in;line-height:normal'><span
style='font-family:"Calibri",sans-serif'>1.1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>“</span><u><span
style='font-family:"Calibri",sans-serif'>Customer Data</span></u><span
style='font-family:"Calibri",sans-serif'>”</span><span style='font-family:"Calibri",sans-serif'>
means data in<span style='letter-spacing:-.05pt'> </span>electronic form input
or collected through the System by or from Customer, including without
limitation by Customer’s Users.</span></p>

<p class=MsoNormal style='margin-bottom:12.0pt;margin-left:.35in;text-align:
justify;text-justify:inter-ideograph;text-indent:-.35in;line-height:normal'><a
name="_Ref421882178"><span style='font-family:"Calibri",sans-serif'>1.2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
style='font-family:"Calibri",sans-serif'>“</span></a><u><span style='font-family:
"Calibri",sans-serif'>System</span></u><span style='font-family:"Calibri",sans-serif'>”
</span><span style='font-family:"Calibri",sans-serif'>means </span><span
style='font-family:"Calibri",sans-serif'>Vendor’s</span><span style='font-family:
"Calibri",sans-serif'> service known as Parlance<span style='color:black'>.</span></span></p>

<p class=MsoNormal style='margin-bottom:12.0pt;margin-left:.35in;text-align:
justify;text-justify:inter-ideograph;text-indent:-.35in;line-height:normal'><span
style='font-family:"Calibri",sans-serif'>1.3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif;color:black'>“<u>User</u>”
means any individual who uses the System on Customer’s behalf or through
Customer’s account or passwords, whether authorized or not.</span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
12.0pt;margin-left:0in;text-align:justify;text-justify:inter-ideograph;
text-indent:0in;line-height:normal'><b><span style='font-family:"Calibri",sans-serif'>2.</span></b><b><u><span
style='font-family:"Calibri",sans-serif'>  PAYMENT</span></u></b><b><span
style='font-family:"Calibri",sans-serif'>.</span></b></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
12.0pt;margin-left:.35in;text-align:justify;text-justify:inter-ideograph;
text-indent:-.35in;line-height:normal'><span style='font-family:"Calibri",sans-serif'>2.1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><u><span
style='font-family:"Calibri",sans-serif'>Fees</span></u><span style='font-family:
"Calibri",sans-serif'>. Customer shall pay Vendor fees as set forth on the
sign-up agreement form. Vendor’s invoices are due within 30 days of issuance.
For late payment, Customer shall pay interest charges from the time the payment
was due at the rate that is the lower of 3.0% per month or the highest rate
permissible under applicable law. Vendor will not be required to refund the Service
Fees under any circumstances.</span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
12.0pt;margin-left:.35in;text-align:justify;text-justify:inter-ideograph;
text-indent:-.35in;line-height:normal'><span style='font-family:"Calibri",sans-serif'>2.2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><u><span
style='font-family:"Calibri",sans-serif'>Taxes</span></u><span
style='font-family:"Calibri",sans-serif'>. Amounts due under this Agreement are
payable to Vendor without deduction and are net of any tax, tariff, duty, or
assessment imposed by any government authority (national, state, provincial, or
local), including without limitation any sales, use, excise, ad valorem,
property, withholding, or value added tax withheld at the source. If applicable
law requires withholding or deduction of such taxes or duties, Customer shall
separately pay Vendor the withheld or deducted amount. However, the prior two
sentences do not apply to taxes based on Vendor’s net income.</span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
12.0pt;margin-left:0in;text-align:justify;text-justify:inter-ideograph;
text-indent:0in;line-height:normal'><a name="_Ref421882346"></a><a
name="_Ref423617965"><b><span style='font-family:"Calibri",sans-serif'>3.</span></b><b><u><span
style='font-family:"Calibri",sans-serif'>  CUSTOMER DATA &amp; PRIVACY</span></u></b></a><b><span
style='font-family:"Calibri",sans-serif'>.</span></b><span style='font-family:
"Calibri",sans-serif'> </span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
12.0pt;margin-left:.35in;text-align:justify;text-justify:inter-ideograph;
text-indent:-.35in;line-height:normal'><span style='font-family:"Calibri",sans-serif'>3.1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><u><span
style='font-family:"Calibri",sans-serif'>Use of Customer Data</span></u><span
style='font-family:"Calibri",sans-serif'>. Unless it receives Customer’s prior
written consent, Vendor: (a) shall not access, process, or otherwise use
Customer Data other than as necessary to facilitate the System; and (b) shall
not intentionally grant any third party access to Customer Data, including
without limitation Vendor’s other customers. Notwithstanding the foregoing,
Vendor may disclose Customer Data as required by applicable law or by proper
legal or governmental authority. Vendor shall give Customer prompt notice of
any such legal or governmental demand and reasonably cooperate with Customer in
any effort to seek a protective order or otherwise to contest such required
disclosure, at Customer’s expense.</span><span style='font-family:"Calibri",sans-serif'>
As between the parties, Customer retains ownership of Customer Data.</span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
12.0pt;margin-left:.35in;text-align:justify;text-justify:inter-ideograph;
text-indent:-.35in;line-height:normal'><span style='font-family:"Calibri",sans-serif'>3.2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><u><span
style='font-family:"Calibri",sans-serif'>Privacy Policy</span></u><span
style='font-family:"Calibri",sans-serif'>.</span><span style='font-family:"Calibri",sans-serif'>
</span><span style='font-family:"Calibri",sans-serif'>The Privacy Policy<span
style='letter-spacing:.1pt'> </span>applies only to<span style='letter-spacing:
-.05pt'> </span>the System and does not apply to any third party website or
service linked to the System or recommended or referred to through the System
or by </span><span style='font-family:"Calibri",sans-serif'>Vendor’s</span><span
style='font-family:"Calibri",sans-serif'> staff.</span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
12.0pt;margin-left:.35in;text-align:justify;text-justify:inter-ideograph;
text-indent:-.35in;line-height:normal'><span style='font-family:"Calibri",sans-serif'>3.3.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><u><span
style='font-family:"Calibri",sans-serif'>Risk of Exposure</span></u><span
style='font-family:"Calibri",sans-serif'>. Customer recognizes and agrees that
hosting data online involves risks of unauthorized disclosure or exposure and
that, in accessing and using the System, Customer assumes such risks. While Vendor
will utilize standard best practices for data protection and security, Vendor
does not offer any representation, warranty, or guarantee that Customer Data
will not be exposed or disclosed through errors or the actions of third
parties.</span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
12.0pt;margin-left:.35in;text-align:justify;text-justify:inter-ideograph;
text-indent:-.35in;line-height:normal'><span style='font-family:"Calibri",sans-serif'>3.4.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><u><span
style='font-family:"Calibri",sans-serif'>Data Accuracy</span></u><span
style='font-family:"Calibri",sans-serif'>. Vendor will have no responsibility
or liability for the accuracy of data uploaded to the System by Customer,
including without limitation Customer Data and any other data uploaded by
Users.</span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
12.0pt;margin-left:.35in;text-align:justify;text-justify:inter-ideograph;
text-indent:-.35in;line-height:normal'><span style='font-family:"Calibri",sans-serif'>3.5.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><u><span
style='font-family:"Calibri",sans-serif'>Data Deletion</span></u><span
style='font-family:"Calibri",sans-serif'>. Vendor may permanently erase
Customer Data from Vendor’s System if </span><span style='font-family:"Calibri",sans-serif'>Customer’s</span><span
style='font-family:"Calibri",sans-serif'> account is delinquent,<span
style='letter-spacing:.1pt'> </span>suspended, or terminated for 30 days or
more.</span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
12.0pt;margin-left:.35in;text-align:justify;text-justify:inter-ideograph;
text-indent:-.35in;line-height:normal'><span style='font-family:"Calibri",sans-serif'>3.6.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><u><span
style='font-family:"Calibri",sans-serif'>Excluded Data</span></u><span
style='font-family:"Calibri",sans-serif'>. Customer represents and warrants
that Customer Data does not and will not include, and Customer has not and shall
not upload or transmit to Vendor's computers or other media, any data (“<u>Excluded
Data</u>”) regulated pursuant to PCI or HIPPA regulations (the &quot;<u>Excluded
Data Laws</u>&quot;). CUSTOMER RECOGNIZES AND AGREES THAT: (a) VENDOR HAS NO
LIABILITY FOR ANY FAILURE TO PROVIDE PROTECTIONS SET FORTH IN THE EXCLUDED DATA
LAWS OR OTHERWISE TO PROTECT EXCLUDED DATA; AND (b) VENDOR’S SYSTEMS ARE NOT
INTENDED FOR MANAGEMENT OR PROTECTION OF EXCLUDED DATA AND MAY NOT PROVIDE
ADEQUATE OR LEGALLY REQUIRED SECURITY FOR EXCLUDED DATA.</span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
12.0pt;margin-left:0in;text-align:justify;text-justify:inter-ideograph;
text-indent:0in;line-height:normal'><b><span style='font-family:"Calibri",sans-serif'>4.</span></b><b><u><span
style='font-family:"Calibri",sans-serif'>  CUSTOMER’S RESPONSIBILITIES &amp;
RESTRICTIONS</span></u></b><b><span style='font-family:"Calibri",sans-serif'>.</span></b></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
12.0pt;margin-left:.35in;text-align:justify;text-justify:inter-ideograph;
text-indent:-.35in;line-height:normal'><a name="_Ref423618168"><span
style='font-family:"Calibri",sans-serif'>4.1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span><u><span style='font-family:"Calibri",sans-serif'>Acceptable Use</span></u></a><span
style='font-family:"Calibri",sans-serif'>. Customer shall comply with the AUP.
Customer shall not: (a) </span><span style='font-family:"Calibri",sans-serif'>use
the System for service bureau or time-sharing purposes or in any other way
allow third parties to exploit the System</span><span style='font-family:"Calibri",sans-serif'>;
(b) provide System passwords or other log-in information to any third party;
(c) </span><span style='font-family:"Calibri",sans-serif'>share non-public System
features or content with any third party; (d) access the System in order<span
style='letter-spacing:.1pt'> </span>to build a competitive product or service,
to build a produ<span style='letter-spacing:.05pt'>c</span>t using similar
ideas, features, functions or graphics of the System, or to copy any ideas,<span
style='letter-spacing:-.1pt'> </span>features, functions or graphics of the System;
or (e) engage in web scraping or data scraping on or related to the System,
including without limitation collection of information through any software
that simulates human activity or any bot or web crawler</span><span
style='font-family:"Calibri",sans-serif'>. In the event that it suspects any
breach of the requirements of this Section </span><span
style='font-family:"Calibri",sans-serif'>4.1</span><span style='font-family:
"Calibri",sans-serif'>, including without limitation by Users, Vendor may
suspend Customer’s access to the System without advanced notice, in addition to
such other remedies as Vendor may have. </span><span style='font-family:"Calibri",sans-serif'>Neither
this Agreement nor <span style='letter-spacing:-.1pt'>t</span>he AUP requi<span
style='letter-spacing:.1pt'>r</span>es that Vendor take any action against </span><span
style='font-family:"Calibri",sans-serif'>Customer or any </span><span
style='font-family:"Calibri",sans-serif'>User or other third party for
violating the<span style='letter-spacing:.05pt'> </span>AUP, this Section </span><span style='font-family:"Calibri",sans-serif'>4.1</span><span style='font-family:
"Calibri",sans-serif'>, or this Agreement, but Vendor is free to t<span
style='letter-spacing:-.05pt'>a</span>ke any such action it sees fit.</span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
12.0pt;margin-left:.35in;text-align:justify;text-justify:inter-ideograph;
text-indent:-.35in;line-height:normal'><span style='font-family:"Calibri",sans-serif'>4.2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><u><span
style='font-family:"Calibri",sans-serif'>Unauthorized Access</span></u><span
style='font-family:"Calibri",sans-serif'>. Customer shall take reasonable steps
to prevent unauthorized access to the System, including without limitation by
protecting</span><span style='font-family:"Calibri",sans-serif'> its passwords
and other log-in information. </span><span style='font-family:"Calibri",sans-serif'>Customer
shall notify Vendor immediately <span style='letter-spacing:.05pt'>o</span>f<span
style='letter-spacing:.05pt'> </span>any known or suspected unauthorized u<span
style='letter-spacing:.05pt'>s</span>e of the System or breach of its security<span
style='letter-spacing:-.05pt'> </span>and shall use </span><span
style='font-family:"Calibri",sans-serif'>best</span><span style='font-family:
"Calibri",sans-serif'> efforts to stop said breach.</span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
12.0pt;margin-left:.35in;text-align:justify;text-justify:inter-ideograph;
text-indent:-.35in;line-height:normal'><span style='font-family:"Calibri",sans-serif;
color:black'>4.3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><u><span
style='font-family:"Calibri",sans-serif'>Compliance with Laws</span></u><span
style='font-family:"Calibri",sans-serif'>. In its use of the System, </span><span
style='font-family:"Calibri",sans-serif;color:black'>Customer shall comply with
all applicable laws, including without limitation laws governing the protection
of personally identifiable information and other laws applicable to the
protection of Customer Data.</span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
12.0pt;margin-left:.35in;text-align:justify;text-justify:inter-ideograph;
text-indent:-.35in;line-height:normal'><span style='font-family:"Calibri",sans-serif'>4.4.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><u><span
style='font-family:"Calibri",sans-serif'>Users &amp; System Access</span></u><span
style='font-family:"Calibri",sans-serif'>. Customer is responsible and liable
for: (a) Users’ use of the System, including without limitation unauthorized User
conduct and any User conduct that would violate the AUP or the requirements of
this Agreement applicable to Customer; and (b) any use of the System through
Customer’s account, whether authorized or unauthorized.</span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
12.0pt;margin-left:0in;text-align:justify;text-justify:inter-ideograph;
text-indent:0in;line-height:normal'><b><span style='font-family:"Calibri",sans-serif'>5.</span></b><b><u><span
style='font-family:"Calibri",sans-serif'>  <a name="_Ref423620046">IP &amp;
FEEDBACK</a></span></u></b><b><span style='font-family:"Calibri",sans-serif'>.</span></b><b><span
style='font-family:"Calibri",sans-serif'> </span></b></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
12.0pt;margin-left:.35in;text-align:justify;text-justify:inter-ideograph;
text-indent:-.35in;line-height:normal'><span style='font-family:"Calibri",sans-serif'>5.1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><u><span
style='font-family:"Calibri",sans-serif'>IP Rights to the System</span></u><span
style='font-family:"Calibri",sans-serif'>. Vendor retains all right, title, and
interest in and to the System, including without limitation all software used
to provide the System and all graphics, user interfaces, logos, and trademarks
reproduced through the System. This Agreement does not grant Customer any
intellectual property license or rights in or to the System or any of its
components. Customer recognizes that the System and its components are
protected by copyright and other laws.</span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
12.0pt;margin-left:.35in;text-align:justify;text-justify:inter-ideograph;
text-indent:-.35in;line-height:normal'><span style='font-family:"Calibri",sans-serif;
color:black'>5.2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><u><span
style='font-family:"Calibri",sans-serif'>Feedback</span></u><span
style='font-family:"Calibri",sans-serif'>. Vendor has not agreed to and does
not agree to treat as confidential any Feedback (as defined below) Customer or
Users provide to Vendor, and nothing in this Agreement or in the parties’
dealings arising out of or related to this Agreement will restrict Vendor’s
right to use, profit from, disclose, publish, keep secret, or otherwise exploit
Feedback, without compensating or crediting Customer or the User in question. Feedback
will not be considered Confidential Information. (“<u>Feedback</u>” refers to
any suggestion or idea for improving or otherwise modifying any of Vendor’s
products or services.)</span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
12.0pt;margin-left:0in;text-align:justify;text-justify:inter-ideograph;
text-indent:0in;line-height:normal;page-break-after:avoid'><b><span
style='font-family:"Calibri",sans-serif'>6.</span></b><b><u><span
style='font-family:"Calibri",sans-serif'>REPRESEN<span style='letter-spacing:
.1pt'>T</span>ATIONS &amp; WARRANTIES</span></u></b><b><span style='font-family:
"Calibri",sans-serif'>. </span></b></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
12.0pt;margin-left:.35in;text-align:justify;text-justify:inter-ideograph;
text-indent:-.35in;line-height:normal'><a name="_Ref423618089"><span
style='font-family:"Calibri",sans-serif'>6.1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span><u><span style='font-family:"Calibri",sans-serif'>From Vendor</span></u></a><span
style='font-family:"Calibri",sans-serif'>. </span><span style='font-family:
"Calibri",sans-serif'>Vendor represents and warrants that it is the owner of
the System and of each and every component thereof, or the recipient of a valid
license thereto, and that it has and will maintain the full power and authority
to grant the rights to use the System set forth in this Agreement without the
further consent of any third party. Vendor’s representations and warranties in
the preceding sentence do not apply to use of the System in combination with
hardware or software not provided by Vendor. In the event of a breach of the
warranty in this Section </span><span style='font-family:"Calibri",sans-serif'>6.1</span><span
style='font-family:"Calibri",sans-serif'>, Vendor, at its own expense, shall
promptly take the following actions: (a) secure for Customer the right to
continue using the System; (b) replace or modify the System to make it
noninfringing; or (c) terminate the infringing features of the Service and refund
to Customer any prepaid fees for such features, in proportion to the portion of
the Term left after such termination. In conjunction with Customer’s right to
terminate for breach where applicable, the preceding sentence states Vendor’s
sole obligation and liability, and Customer’s sole remedy, for breach of the
warranty in this Section </span><span style='font-family:"Calibri",sans-serif'>6.1</span><span
style='font-family:"Calibri",sans-serif'> and for potential or actual
intellectual property infringement by the System.</span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
12.0pt;margin-left:.35in;text-align:justify;text-justify:inter-ideograph;
text-indent:-.35in;line-height:normal'><span style='font-family:"Calibri",sans-serif'>6.2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><u><span
style='font-family:"Calibri",sans-serif'>From Customer</span></u><span
style='font-family:"Calibri",sans-serif'>.</span><span style='font-family:"Calibri",sans-serif'>
</span><span style='font-family:"Calibri",sans-serif'>Customer represents and
warrants that: (a) it has the full right and authority to enter into, execute,
and perform its obligations under this Agreement and that no pending or
threatened claim or litigation known to it would have a material adverse impact
on its ability to perform as required by this Agreement; (b) it has accurately
identified it<span style='letter-spacing:.05pt'>s</span>e<span
style='letter-spacing:-.05pt'>l</span>f and it has not provided any inaccurate
information about itself to or through the System; and (c) it is a corporation,
the sole proprietorship of an individual 18 years or older, or another e<span
style='letter-spacing:-.05pt'>n</span>tity authorized to do business pursuant<span
style='letter-spacing:.05pt'> </span>to<span style='letter-spacing:.05pt'> </span>applicable<span
style='letter-spacing:.05pt'> </span>law.</span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
12.0pt;margin-left:.35in;text-align:justify;text-justify:inter-ideograph;
text-indent:-.35in;line-height:normal'><a name="_Ref423620111"><span
style='font-family:"Calibri",sans-serif'>6.3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span><u><span style='font-family:"Calibri",sans-serif'>Warranty Disclaimers</span></u></a><span
style='font-family:"Calibri",sans-serif'>. Except to the extent set forth in
Section </span><span style='font-family:"Calibri",sans-serif'>6.1</span><span
style='font-family:"Calibri",sans-serif'> above, CUSTOMER ACCEPTS THE SYSTEM
“AS IS” AND AS AVAILABLE, WITH NO REPRESENTATION OR WARRANTY OF ANY KIND,
EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION IMPLIED WARRANTIES OF
MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT OF
INTELLECTUAL PROPERTY RIGHTS, OR ANY IMPLIED WARRANTY ARISING FROM STATUTE,
COURSE OF DEALING, COURSE OF PERFORMANCE, OR USAGE OF TRADE. WITHOUT LIMITING
THE GENERALITY OF THE FOREGOING: (a) VENDOR HAS NO OBLIGATION TO INDEMNIFY OR
DEFEND CUSTOMER OR USERS AGAINST CLAIMS RELATED TO INFRINGEMENT OF INTELLECTUAL
PROPERTY; (b) VENDOR DOES NOT REPRESENT OR WARRANT THAT THE SYSTEM WILL PERFORM
WITHOUT INTERRUPTION OR ERROR; AND (c) VENDOR DOES NOT REPRESENT OR WARRANT<span
style='letter-spacing:.1pt'> THAT THE SYSTEM IS SECURE FROM HACKING OR OTHER
UNAUTHORIZED INTRUSION OR THAT CUSTOMER DATA WILL REMAIN PRIVATE OR SECURE.</span></span><span
style='font-family:"Calibri",sans-serif;letter-spacing:.1pt'> </span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
12.0pt;margin-left:0in;text-align:justify;text-justify:inter-ideograph;
text-indent:0in;line-height:normal'><b><span style='font-family:"Calibri",sans-serif'>7.</span></b><b><u><span
style='font-family:"Calibri",sans-serif'>  <a name="_Ref423620130">INDEMNIFI<span
style='letter-spacing:-.1pt'>C</span>ATION</a></span></u></b><b><span
style='font-family:"Calibri",sans-serif'>.</span></b><span style='font-family:
"Calibri",sans-serif'> Customer shall defend, indemnify, and hold harmless
Vendor and the Vendor Associates (as defined below) against any “<u>Indemnified
Claim</u>,” meaning any third party claim, suit, or proceeding arising out of
or related to Customer's alleged or actual use of, misuse of, or failure to use
the System, including without limitation: (a) claims by Users or by Customer's
employees, as well as by Customer’s own customers; (b) claims related to
unauthorized disclosure or exposure of personally identifiable information or
other private information, including Customer Data; (c) claims related to
infringement or violation of a copyright, trademark, trade secret, or privacy
or confidentiality right by written material, images, logos or other content
uploaded to the System through Customer’s account, including without limitation
by Customer Data; and (d) claims that use of the System through Customer’s
account harasses, defames, or defrauds a third party or violates the CAN-Spam
Act of 2003 or any other law or restriction on electronic advertising or
telecommunications. Indemnified Claims include, without limitation, claims
arising out of or related to Vendor’s negligence. Customer’s obligations set
forth in this Article </span><span
style='font-family:"Calibri",sans-serif'>7</span><span style='font-family:"Calibri",sans-serif'>
include retention and payment of attorneys and payment of court costs, as well
as settlement at Customer’s expense and payment of judgments. Vendor will have
the right, not to be exercised unreasonably, to reject any settlement or compromise
that requires that it admit wrongdoing or liability or subjects it to any
ongoing affirmative obligations. (The “<u>Vendor Associates</u>” are Vendor’s
officers, directors, shareholders, parents, subsidiaries, agents, successors,
and assigns.)</span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
12.0pt;margin-left:0in;text-align:justify;text-justify:inter-ideograph;
text-indent:0in;line-height:normal;page-break-after:avoid'><a
name="_Ref421882007"><b><span style='font-family:"Calibri",sans-serif'>8.</span></b><b><u><span
style='font-family:"Calibri",sans-serif'>  </span></u></b></a><a
name="_Ref423620147"><b><u><span style='font-family:"Calibri",sans-serif'>LIMITATION
OF LIABILIT<span style='letter-spacing:-.1pt'>Y</span></span></u></b></a><b><span
style='font-family:"Calibri",sans-serif'>.</span></b></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
12.0pt;margin-left:.35in;text-align:justify;text-justify:inter-ideograph;
text-indent:-.35in;line-height:normal'><span style='font-family:"Calibri",sans-serif'>8.1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><u><span
style='font-family:"Calibri",sans-serif'>Dollar Cap</span></u><span
style='font-family:"Calibri",sans-serif'>. VENDOR’S CUMULATIVE LIABILTY FOR ALL
CLAIMS ARISING OUT OF OR RELATED TO THIS AGREEMENT WILL NOT EXCEED THE TOTAL
AMOUNT PAID TO VENDOR BY CUSTOMER</span><span style='font-family:"Calibri",sans-serif'>.</span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
12.0pt;margin-left:.35in;text-align:justify;text-justify:inter-ideograph;
text-indent:-.35in;line-height:normal'><span style='font-family:"Calibri",sans-serif'>8.2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><u><span
style='font-family:"Calibri",sans-serif'>Excluded Damages</span></u><span
style='font-family:"Calibri",sans-serif'>. IN NO EVENT WILL VENDOR BE LIABLE
FOR LOST PROFITS OR LOSS OF BUSINESS OR FOR ANY CONSEQUENTIAL, INDIRECT,
SPECIAL, INCIDENTAL, OR PUNITIVE DAMAGES ARISING OUT OF OR RELATED TO THIS
AGREEMENT.</span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
12.0pt;margin-left:.35in;text-align:justify;text-justify:inter-ideograph;
text-indent:-.35in;line-height:normal'><span style='font-family:"Calibri",sans-serif'>8.3.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><u><span
style='font-family:"Calibri",sans-serif'>Clarifications &amp; Disclaimers</span></u><span
style='font-family:"Calibri",sans-serif'>. THE LIABILITIES LIMITED BY THIS
ARTICLE </span><span style='font-family:"Calibri",sans-serif'>8</span><span
style='font-family:"Calibri",sans-serif'> APPLY: (a) TO LIABILITY FOR
NEGLIGENCE; (b) REGARDLESS OF THE FORM OF ACTION, WHETHER IN CONTRACT, TORT,
STRICT PRODUCT LIABILITY, OR OTHERWISE; (c) EVEN IF VENDOR IS ADVISED IN
ADVANCE OF THE POSSIBILITY OF THE DAMAGES IN QUESTION AND EVEN IF SUCH DAMAGES
WERE FORESEEABLE; AND (d) EVEN IF CUSTOMER’S REMEDIES FAIL OF THEIR ESSENTIAL
PURPOSE. If applicable law limits the application of the provisions of this
Article </span><span style='font-family:"Calibri",sans-serif'>8</span><span
style='font-family:"Calibri",sans-serif'>, Vendor’s liability will be limited
to the maximum extent permissible. For the avoidance of doubt, Vendor’s
liability limits and other rights set forth in this Article </span><span style='font-family:"Calibri",sans-serif'>8</span><span style='font-family:"Calibri",sans-serif'>
apply likewise to Vendor’s <span style='letter-spacing:-.1pt'>a</span>ffiliates,
licen<span style='letter-spacing:.05pt'>s</span>ors, suppliers, advertisers,
agents, sponsors, direc<span style='letter-spacing:-.1pt'>t</span>ors,
officers, employees, consultants, and other rep<span style='letter-spacing:
.05pt'>r</span>esentatives.</span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
12.0pt;margin-left:0in;text-align:justify;text-justify:inter-ideograph;
text-indent:0in;line-height:normal'><a name="_Ref423608140"><b><span
style='font-family:"Calibri",sans-serif'>9.</span></b><b><u><span
style='font-family:"Calibri",sans-serif'>  Term<span style='letter-spacing:
.05pt'> </span>&amp; Termination.</span></u></b></a></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
12.0pt;margin-left:.35in;text-align:justify;text-justify:inter-ideograph;
text-indent:-.35in;line-height:normal'><a name="_Ref421882237"><span
style='font-family:"Calibri",sans-serif'>9.1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span><u><span style='font-family:"Calibri",sans-serif'>Te<span
style='letter-spacing:.1pt'>r</span><span style='letter-spacing:-.1pt'>m</span></span></u></a><span
style='font-family:"Calibri",sans-serif'>. The term of this Agreement (the “<u>Term</u>”)
will commence on the Effective Date and continue until terminated by either
party.</span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
12.0pt;margin-left:.35in;text-align:justify;text-justify:inter-ideograph;
text-indent:-.35in;line-height:normal'><span style='font-family:"Calibri",sans-serif'>9.2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><i><u><span
style='font-family:"Calibri",sans-serif'>Te<span style='letter-spacing:.1pt'>r</span><span
style='letter-spacing:-.1pt'>m</span>ination</span></u></i><span
style='font-family:"Calibri",sans-serif'>.</span><span style='font-family:"Calibri",sans-serif'>
</span><span style='font-family:"Calibri",sans-serif'>Either party may
terminate this Agreement at any time for any reason with written notice to the
other party.</span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
12.0pt;margin-left:.35in;text-align:justify;text-justify:inter-ideograph;
text-indent:-.35in;line-height:normal'><span style='font-family:"Calibri",sans-serif'>9.3.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><u><span
style='font-family:"Calibri",sans-serif'>Effects of Terminatio<span
style='letter-spacing:-.05pt'>n</span></span></u><span style='font-family:"Calibri",sans-serif'>.</span><span
style='font-family:"Calibri",sans-serif'> </span><span style='font-family:"Calibri",sans-serif'>Upon
termination of this Agreement, Customer shall cease all use of the System and
delete, destroy, or return all copies of the Documentation in its possession or
control. The following provisions will survive termination or expiration of
this Agreement: (a) any obligation of Customer to pay fees incurred before
termination; (b) Articles and Sections </span>5<span
style='font-family:"Calibri",sans-serif'> (<i>IP &amp; Feedback</i>), </span><span style='font-family:"Calibri",sans-serif'>6.3</span><span style='font-family:
"Calibri",sans-serif'> (<i>Warranty Disclaimers</i>), </span>7<span style='font-family:"Calibri",sans-serif'> (<i>Indemnification</i>), and </span><span style='font-family:"Calibri",sans-serif'>8</span><span style='font-family:"Calibri",sans-serif'>
(<i>Limitation of Liability</i>); and (c) any other provision of this Agreement
that must survive to fulfill its essential purpose.</span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
12.0pt;margin-left:0in;text-align:justify;text-justify:inter-ideograph;
text-indent:0in;line-height:normal;page-break-after:avoid'><b><span
style='font-family:"Calibri",sans-serif'>10.</span></b><b><u><span
style='font-family:"Calibri",sans-serif'>MISCELLANEOUS</span></u></b><b><span
style='font-family:"Calibri",sans-serif'>.</span></b></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
12.0pt;margin-left:.35in;text-align:justify;text-justify:inter-ideograph;
text-indent:-.35in;line-height:normal'><span style='font-family:"Calibri",sans-serif'>10.1.<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><u><span
style='font-family:"Calibri",sans-serif'>Notices</span></u><span
style='font-family:"Calibri",sans-serif'>.</span><span style='font-family:"Calibri",sans-serif'>
</span><span style='font-family:"Calibri",sans-serif'>Vendor may send notices
pursuant<span style='letter-spacing:-.05pt'> </span>to this Agreement to </span><span
style='font-family:"Calibri",sans-serif'>Customer’s</span><span
style='font-family:"Calibri",sans-serif'> email contact points provided by
Customer, and such notices will be deemed received 24 hours after they are
sent. Customer may send notices pursuant to this Agreement to Parlance, 437
Memorial Dr SE, Suite A-2, Atlanta, GA 30312, and such notic<span
style='letter-spacing:.05pt'>e</span>s will be deemed received 72 hours after
they are sent. Notices can also be sent via email to support@parlance.app and
are considered received upon acknowledgement from Vendor staff.</span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
12.0pt;margin-left:.35in;text-align:justify;text-justify:inter-ideograph;
text-indent:-.35in;line-height:normal'><span style='font-family:"Calibri",sans-serif'>10.2.<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><u><span
style='font-family:"Calibri",sans-serif'>Force Majeure</span></u><span
style='font-family:"Calibri",sans-serif'>. No delay, failure, or default, other
than a failure to pay fees when due, will constitute a breach of this Agreement
to the extent caused by acts of war, terrorism, hurricanes, earthquakes, other
acts of God or of nature, strikes or other labor disputes, riots or other acts
of civil disorder, embargoes, or other causes beyond the performing party’s
reasonable control.</span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
12.0pt;margin-left:.35in;text-align:justify;text-justify:inter-ideograph;
text-indent:-.35in;line-height:normal'><a name="_Ref423609644"><span
style='font-family:"Calibri",sans-serif'>10.3.<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span><u><span style='font-family:"Calibri",sans-serif'>Assignment
&amp; Successors</span></u></a><span style='font-family:"Calibri",sans-serif'>.
Customer may not assign this Agreement or any of its rights or obligations
hereunder without Vendor’s express written consent. Except to the extent
forbidden in this Section </span><span style='font-family:"Calibri",sans-serif'>10.3</span><span
style='font-family:"Calibri",sans-serif'>, this Agreement will be binding upon
and inure to the benefit of the parties’ respective successors and assigns.</span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
12.0pt;margin-left:.35in;text-align:justify;text-justify:inter-ideograph;
text-indent:-.35in;line-height:normal'><span style='font-family:"Calibri",sans-serif'>10.4.<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><u><span
style='font-family:"Calibri",sans-serif'>Severability</span></u><span
style='font-family:"Calibri",sans-serif'>. To the extent permitted by
applicable law, the parties hereby waive any provision of law that would render
any clause of this Agreement invalid or otherwise unenforceable in any respect.
In the event that a provision of this Agreement is held to be invalid or
otherwise unenforceable, such provision will be interpreted to fulfill its
intended purpose to the maximum extent permitted by applicable law, and the
remaining provisions of this Agreement will continue in full force and effect.</span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
12.0pt;margin-left:.35in;text-align:justify;text-justify:inter-ideograph;
text-indent:-.35in;line-height:normal'><span style='font-family:"Calibri",sans-serif'>10.5.<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><u><span
style='font-family:"Calibri",sans-serif'>No Waiver</span></u><span
style='font-family:"Calibri",sans-serif'>. Neither party will be deemed to have
waived any of its rights under this Agreement by lapse of time or by any
statement or representation other than by an authorized representative in an
explicit written waiver. No waiver of a breach of this Agreement will
constitute a waiver of any other breach of this Agreement.</span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
12.0pt;margin-left:.35in;text-align:justify;text-justify:inter-ideograph;
text-indent:-.35in;line-height:normal'><a name="_Ref451867456"><span
style='font-family:"Calibri",sans-serif'>10.6.<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span><u><span style='font-family:"Calibri",sans-serif'>Choice of Law
&amp; Jurisdiction</span></u></a><span style='font-family:"Calibri",sans-serif'>:
This Agreement and all claims arising out of or related to this Agreement will
be governed solely by the internal laws of the State of Georgia, including
without limitation applicable federal law, without reference to: (a) any
conflicts of law principle that would apply the substantive laws of another
jurisdiction to the parties’ rights or duties; (b) the 1980 United Nations
Convention on Contracts for the International Sale of Goods; or (c) other
international laws. The parties consent to the personal and exclusive
jurisdiction of the federal and state courts of Atlanta, GA]. This Section </span><span style='font-family:"Calibri",sans-serif'>10.6</span><span style='font-family:
"Calibri",sans-serif'> governs all claims arising out of or related to this
Agreement, including without limitation tort claims</span><span
style='font-family:"Calibri",sans-serif'>.</span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
12.0pt;margin-left:.35in;text-align:justify;text-justify:inter-ideograph;
text-indent:-.35in;line-height:normal'><span style='font-family:"Calibri",sans-serif'>10.7.<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><u><span
style='font-family:"Calibri",sans-serif'>Conflicts</span></u><span
style='font-family:"Calibri",sans-serif'>. In the event of any conflict between
this Agreement and any Vendor poli<span style='letter-spacing:.05pt'>c</span>y
posted online, including witho<span style='letter-spacing:.05pt'>u</span>t
limitation the AUP or Privacy Poli<span style='letter-spacing:.05pt'>c</span>y,
the terms of this Agree<span style='letter-spacing:.05pt'>m</span>ent will
govern.</span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
12.0pt;margin-left:.35in;text-align:justify;text-justify:inter-ideograph;
text-indent:-.35in;line-height:normal'><span style='font-family:"Calibri",sans-serif'>10.8.<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><u><span
style='font-family:"Calibri",sans-serif'>Technology Export</span></u><span
style='font-family:"Calibri",sans-serif'>. Customer shall not: (a) permit any
third party to access or use the System in violation of any U.S. law or
regulation; or (b) export any software provided by Vendor or otherwise remove
it from the United States except in compliance with all applicable U.S. laws
and regulations. Without limiting the generality of the foregoing, Customer shall
not permit any third party to access or use the System in, or export such
software to, a country subject to a United States embargo (as of the Effective
Date, Cuba, Iran, North Korea, Sudan, and Syria).</span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
12.0pt;margin-left:.35in;text-align:justify;text-justify:inter-ideograph;
text-indent:-.35in;line-height:normal'><span style='font-family:"Calibri",sans-serif'>10.9.<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><u><span
style='font-family:"Calibri",sans-serif;letter-spacing:.05pt'>Entire Agreement</span></u><span
style='font-family:"Calibri",sans-serif'>. </span><span style='font-family:
"Calibri",sans-serif'>This Agreement sets forth the entire agreement of the
parties and supersedes all prior or contemporaneous writings, negotiations, and
discussions with respect to its subject matter. Neither party has relied upon
any such prior or contemporaneous communications</span><span style='font-family:
"Calibri",sans-serif'>.</span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
12.0pt;margin-left:.35in;text-align:justify;text-justify:inter-ideograph;
text-indent:-.35in;line-height:normal'><a name="_Ref423608182"><span
style='font-family:"Calibri",sans-serif'>10.10.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span><u><span style='font-family:"Calibri",sans-serif;letter-spacing:
.05pt'>A</span></u></a><u><span style='font-family:"Calibri",sans-serif;
letter-spacing:-.1pt'>m</span></u><u><span style='font-family:"Calibri",sans-serif;
letter-spacing:.05pt'>end</span></u><u><span style='font-family:"Calibri",sans-serif;
letter-spacing:-.1pt'>m</span></u><u><span style='font-family:"Calibri",sans-serif;
letter-spacing:.05pt'>en</span></u><u><span style='font-family:"Calibri",sans-serif'>t</span></u><span
style='font-family:"Calibri",sans-serif'>.</span><span style='font-family:"Calibri",sans-serif'>
</span><span style='font-family:"Calibri",sans-serif'>Vendor may amend this
Agreement from time to time by posting an amended version at its Website and
sending Customer written notice thereof. Such amendment will be deemed accepted
and become effective 30 days after such notice (the “<u>Proposed Amendment Date</u>”)
unless Customer first gives Vendor written notice of rejection of the
amendment. In the event of such rejection, this Agreement will continue under
its original provisions, and the amendment will become effective at the start
of Customer’s next Term following the Proposed Amendment Date (unless Customer
first terminates this Agreement pursuant to Article </span><span style='font-family:"Calibri",sans-serif'>9</span><span style='font-family:"Calibri",sans-serif'>,
<i>Term &amp; Termination</i>). Customer’s continued use of the Service
following the effective date of an amendment will confirm Customer’s consent
thereto. This Agreement may not be amended in any other way except through a
written agreement by authorized representatives of each party. Vendor may
revise the Privacy Policy and Acceptable Use Policy at any time by posting a
new version of either at the Website, and such new version will become
effective on the date it is posted; provided if such amendment materially
reduces Customer’s rights or protections, notice and consent will be subject to
the requirements above in this Section </span><span
style='font-family:"Calibri",sans-serif'>10.10</span><span style='font-family:
"Calibri",sans-serif'>.</span></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;text-justify:
inter-ideograph;line-height:normal'><b><span style='font-family:"Calibri",sans-serif'>&nbsp;</span></b></p>

</div>

</div>
</section>
