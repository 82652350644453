import {NgModule, Injectable} from '@angular/core';
import {Routes, Router, RouterModule, NavigationEnd} from '@angular/router';
import {ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy} from '@angular/router';

import {filter} from 'rxjs/operators';

import {LoginPage} from './pages/login/login.page';
import {ChangePasswordPage} from './pages/change-password/change-password.page';
import {AuthGuardService} from './services';
import {Roles} from './model';
import {MainPage} from './pages/brochure/main/main.page';
import {OverviewComponent} from './pages/brochure/overview/overview.component';
import {HowItWorksComponent} from './pages/brochure/how-it-works/how-it-works.component';
import {OrgSignUpComponent} from './pages/brochure/org-sign-up/org-sign-up.component';
import {PricingComponent} from './pages/brochure/pricing/pricing.component';
import {GetStartedComponent} from './pages/brochure/get-started/get-started.component';
import {PrivacyPolicyComponent} from './pages/brochure/privacy-policy/privacy-policy.component';
import {TermsOfServiceComponent} from './pages/brochure/terms-of-service/terms-of-service.component';
import {TouchNetCallbackModule} from './pages/touch-net-callback/touch-net-callback.module';

const routes: Routes = [
  {
    path: 'admin/:orgId',
    loadChildren: () => import('./pages/app-view/app-view.module').then(m => m.AppViewPageModule),
  },
  {
    path: 'go/:orgId',
    loadChildren: () => import('./pages/user/app-user-view/app-user-view.module').then(m => m.AppUserViewPageModule),
  },
  {
    path: 'touchnet-return',
    loadChildren: () => import('./pages/touch-net-callback/touch-net-callback.module').then(m => m.TouchNetCallbackModule),
  },
  {
    path: 'give/:id',
    loadChildren: () => import('./pages/give/give.module').then(m => m.GiveModule),
  },
  {
    path: '',
    component: MainPage,
    children: [
      {path: '', component: OverviewComponent, pathMatch: 'full'},
      {path: 'how-it-works', component: HowItWorksComponent},
      {path: 'signup', component: GetStartedComponent},
      {path: 'pricing', component: PricingComponent},
      {path: 'login', component: LoginPage},
      {path: 'loginadmin', loadChildren: () => import('./pages/loginadmin/login-admin.module').then(m => m.LoginAdminPageModule)},
      {path: 'changepassword', component: ChangePasswordPage},
      {path: 'orgstart', component: OrgSignUpComponent},
      {path: 'privacy', component: PrivacyPolicyComponent},
      {path: 'terms', component: TermsOfServiceComponent}
    ]
  },
];

@Injectable()
export class RoutingState {
  private history = [];

  constructor(
    private router: Router
  ) {
  }

  public loadRouting(): void {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(({urlAfterRedirects}: NavigationEnd) => {
        this.history = [...this.history, urlAfterRedirects];
      });
  }

  public getHistory(): string[] {
    return this.history;
  }

  public getPreviousUrl(): string {
    return this.history[this.history.length - 1] || '/';
  }
}

export class CustomReuseStrategy implements RouteReuseStrategy {
  handlers: { [key: string]: DetachedRouteHandle } = {};

  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return false;
    // console.log(route.data['neverReuse'], (!route.data['neverReuse']), ((!route.data['neverReuse']) || false));
    // return (!route.data['neverReuse']) || false;
  }

  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
    // console.log("Store");
    // if (!route.data['neverReuse']) {
    //   this.handlers[route.routeConfig.path] = handle;
    // }
  }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return false;
    // console.log("shouldAttach");
    // return !!route.routeConfig && !!this.handlers[route.routeConfig.path];
  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    return null;
    // console.log("retrieve");
    // if (!route.routeConfig) return null;
    // return this.handlers[route.routeConfig.path];
  }

  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    return future.routeConfig === curr.routeConfig;
    // console.log("shouldReuseRoute", future.data['neverReuse'], (!future.data['neverReuse']), ((!future.data['neverReuse']) || false));
    // return false;
    // //return (!future.data['neverReuse']) || false;
  }
}

@NgModule({
  imports: [RouterModule.forRoot(routes, {relativeLinkResolution: 'corrected'})],
  exports: [RouterModule],
  providers: [
    RoutingState,
    //{ provide: RouteReuseStrategy, useClass: CustomReuseStrategy}
  ]
})
export class AppRoutingModule {
}
